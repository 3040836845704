import { useEffect, useState } from "react";
import { IUserInfo, useAuthDataContext } from "../../components/AuthProvider";
import {
  CanvasByIdMap,
  useCanvasesContext,
} from "../../components/CanvasesProvider";
import {
  FolderByIdMap,
  isHomeFolder,
  isRootFolder,
  isTrashFolder,
  parentTrashFolder,
  useFoldersContext,
} from "../../components/FoldersProvider";
import { ICanvas, IFolder, IResource } from "../../interfaces";
import { ModalStaticFunctions } from "antd/lib/modal/confirm";
import {
  Button,
  Checkbox,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Space,
} from "antd";
import {
  DeleteOutlined,
  SearchOutlined,
  FolderAddOutlined,
  FileAddOutlined,
  DragOutlined,
  CopyOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { FolderViewList } from "./FolderViewList";
import { FolderViewGrid } from "./FolderViewGrid";
import { FolderBreadcrumbs } from "./FolderBreadcrumbs";
import { createResourceModal } from "./ModalUtils";
import Axios from "axios";
import { usePersistedState } from "../../util";
import { getCollator } from "../../sorting";

// Different visualization modes for the folder view
enum ViewMode {
  Grid,
  List,
}

export interface IFolderViewProps {
  external_url: string;
  currentFolder: IFolder;
  onNavigateToFolder: (folderId: string) => void;
  onSelectResources: (resources: Set<string>) => void;
  onDoubleClickResource: (event: React.MouseEvent, resourceId: string) => void;
  selectedResources: Set<string>;
  onDeleteResource: (resourceIds: string[]) => void;
  onCopyResource: (resourceIds: Set<string>) => void;
  onMoveResource: (resourceIds: Set<string>) => void;
  modal: Omit<ModalStaticFunctions, "warn">;
}

export interface IFilteredFolderViewProps extends IFolderViewProps {
  nameFilter: string;
  childFolders: IFolder[];
  childCanvases: ICanvas[];
}

// Component to visualize the contents of a single folder and a UI to copy, move
// and delete its child resources.
export const FolderView: React.FunctionComponent<IFolderViewProps> = (
  props
) => {
  const { user } = useAuthDataContext();
  const { canvases } = useCanvasesContext();
  const { folders } = useFoldersContext();

  // Is the UI in grid view or list view?
  const [mode, setMode] = usePersistedState(
    "canvas-list-view-mode",
    ViewMode.Grid
  );

  // Filter string input by the user
  const [nameFilter, setNameFilter] = useState<string>("");

  // Child resources of the current folder
  const [childFolders, setChildFolders] = useState<IFolder[]>([]);
  const [childCanvases, setChildCanvases] = useState<ICanvas[]>([]);

  // Update the list of subfolders as needed
  useEffect(() => {
    const newChildFolders: IFolder[] = [];

    folders.forEach((folder, _key) => {
      if (folder.folder_id === props.currentFolder.id) {
        // Apply filtering
        const title = folder.name.toLowerCase();
        if (title.includes(nameFilter)) {
          newChildFolders.push(folder);
        }
      }
    });

    sortResources(newChildFolders);
    setChildFolders(newChildFolders);
  }, [folders, props.currentFolder.id, nameFilter]);

  // Update the list of canvases in this folder as needed
  useEffect(() => {
    const newChildCanvases: ICanvas[] = [];

    canvases.forEach((canvas, _key) => {
      if (canvas.folder_id === props.currentFolder.id) {
        // Apply filtering
        const title = canvas.name.toLowerCase();
        if (title.includes(nameFilter)) {
          newChildCanvases.push(canvas);
        }
      }
    });

    sortResources(newChildCanvases);
    setChildCanvases(newChildCanvases);
  }, [canvases, props.currentFolder.id, nameFilter]);

  const isDeleteVisible = isDeleteEnabled(
    props.selectedResources,
    canvases,
    folders,
    user
  );

  const isCreateFolderVisible = isCreateFolderEnabled(
    props.selectedResources,
    props.currentFolder,
    user
  );
  const isCreateCanvasVisible = isCreateCanvasEnabled(
    props.selectedResources,
    props.currentFolder,
    user
  );

  const isCopyVisible = isCopyEnabled(props.selectedResources);
  const isMoveVisible = isMoveEnabled(props.selectedResources);

  const isTrash =
    isTrashFolder(props.currentFolder) || props.currentFolder.in_trash;

  const trashFolderToEmpty = parentTrashFolder(props.currentFolder, folders);

  const isTrashFolderEmpty = trashFolderToEmpty
    ? isFolderEmpty(trashFolderToEmpty.id, canvases, folders)
    : true;

  const regularButtons = (
    <>
      {!isTrash && isCreateFolderVisible && (
        <Button
          icon={<FolderAddOutlined />}
          onClick={() => {
            createResourceModal(
              "folder",
              props.currentFolder.id,
              (resourceId) => {
                props.onSelectResources(new Set([resourceId]));
              }
            );
          }}
        >
          New folder
        </Button>
      )}

      {!isTrash && isCreateCanvasVisible && (
        <Button
          icon={<FileAddOutlined />}
          onClick={() => {
            createResourceModal(
              "canvas",
              props.currentFolder.id,

              (resourceId) => {
                props.onSelectResources(new Set([resourceId]));
              }
            );
          }}
        >
          New canvas
        </Button>
      )}

      {isMoveVisible && (
        <Button
          icon={<DragOutlined />}
          onClick={() => {
            props.onMoveResource(props.selectedResources);
          }}
        >
          {isTrash ? "Restore" : "Move"}
        </Button>
      )}

      {!isTrash && isCopyVisible && (
        <Button
          icon={<CopyOutlined />}
          onClick={() => {
            props.onCopyResource(props.selectedResources);
          }}
        >
          Copy
        </Button>
      )}

      {!isTrash && isDeleteVisible && (
        <Button
          danger
          type="primary"
          icon={<DeleteOutlined />}
          onClick={() => {
            props.modal.confirm({
              content: "Move selected resources to trash?",
              okText: "Trash",
              okButtonProps: { danger: true },
              onOk: () => {
                props.onDeleteResource(Array.from(props.selectedResources));
              },
            });
          }}
        >
          Remove
        </Button>
      )}

      {isTrash && (
        <Button
          disabled={isTrashFolderEmpty}
          danger
          type="primary"
          onClick={() => {
            Modal.confirm({
              content:
                "Are you sure you want to permanently erase the items in trash?",
              okText: "Empty trash",
              okButtonProps: { danger: true },
              onOk: () => {
                const hideProgressMsg = message.loading("Emptying trash...");

                // Should never happen
                if (trashFolderToEmpty === undefined) {
                  hideProgressMsg();
                  console.error("Trash folder not found.");
                  return false;
                }

                Axios.delete(
                  `/api/dashboard/canvas-folders/${trashFolderToEmpty.id}/children`
                )
                  .then(() => {
                    if (props.currentFolder.in_trash) {
                      // Need to adjust the current folder if it is a subfolder in
                      // trash. Otherwise we'd be left with non-existing folder as
                      // the current folder.
                      props.onNavigateToFolder(trashFolderToEmpty.id);
                    }
                  })
                  .catch((error) => {
                    const serverReply = error.response?.data?.msg;
                    const msg = `Failed to empty trash folder`;

                    console.error(msg, error.response);
                    message.error(msg + ` (${serverReply})`);
                  })
                  .finally(() => {
                    hideProgressMsg();
                  });

                return false;
              },
            });
          }}
        >
          Empty trash
        </Button>
      )}
    </>
  );

  const isPartialSelect =
    props.selectedResources.size > 0 &&
    props.selectedResources.size !== childFolders.length + childCanvases.length;
  const isAllSelect =
    props.selectedResources.size > 0 &&
    props.selectedResources.size === childFolders.length + childCanvases.length;

  const selectionMessage =
    props.selectedResources.size === 0
      ? "Select all"
      : `${props.selectedResources.size} item${
          props.selectedResources.size > 1 ? "s" : ""
        } selected`;

  const selectionCheckbox = (
    <Checkbox
      onChange={(event) => {
        if (event.target.checked) {
          const allResourceIds = childCanvases
            .map((x) => x.id)
            .concat(childFolders.map((x) => x.id));
          props.onSelectResources(new Set(allResourceIds));
        } else {
          props.onSelectResources(new Set());
        }
      }}
      indeterminate={isPartialSelect}
      checked={isAllSelect}
    >
      {selectionMessage}
    </Checkbox>
  );

  return (
    <div className="folder-view-container">
      <Row className="search-row">
        <Input
          placeholder="Filter this folder..."
          allowClear
          prefix={<SearchOutlined />}
          onChange={(event) => {
            setNameFilter(event.target.value.toLowerCase());
          }}
        />
      </Row>
      <Row className="toolbar">
        <FolderBreadcrumbs
          folderId={props.currentFolder.id}
          onNavigateToFolder={props.onNavigateToFolder}
        />

        <Space direction="horizontal" className="button-group-1">
          {regularButtons}
        </Space>
      </Row>
      {mode === ViewMode.Grid && (
        <FolderViewGrid
          {...props}
          nameFilter={nameFilter}
          childCanvases={childCanvases}
          childFolders={childFolders}
        />
      )}
      {mode === ViewMode.List && (
        <FolderViewList
          {...props}
          nameFilter={nameFilter}
          childCanvases={childCanvases}
          childFolders={childFolders}
        />
      )}
      <Row className="footer">
        {selectionCheckbox}
        <Radio.Group
          size="small"
          defaultValue={mode}
          onChange={(e) => {
            setMode(e.target.value);
          }}
        >
          <Radio.Button value={ViewMode.Grid}>
            <AppstoreOutlined />
          </Radio.Button>
          <Radio.Button value={ViewMode.List}>
            <UnorderedListOutlined />
          </Radio.Button>
        </Radio.Group>
      </Row>
    </div>
  );
};

// Sort an array of resources by name
function sortResources(resources: IResource[]): void {
  const collator = getCollator();

  resources.sort((a, b) => {
    return collator.compare(a.name, b.name);
  });
}

// Given an array of selected resources, determine if at least one of them can
// be deleted.
function isDeleteEnabled(
  selectedResourceIds: Set<string>,
  canvases: CanvasByIdMap,
  folders: FolderByIdMap,
  currentUser: IUserInfo
): boolean {
  for (const id of selectedResourceIds) {
    const canvas = canvases.get(id);
    if (canvas) {
      if (currentUser.admin || canvas.access === "owner") return true;
    }

    const folder = folders.get(id);
    if (folder) {
      const isTrash = isTrashFolder(folder);
      const isHome = isHomeFolder(folder);
      const isRoot = isRootFolder(folder);

      if (!isTrash && !isHome && !isRoot) {
        if (currentUser.admin || folder.access === "owner") return true;
      }
    }
  }

  return false;
}

// Given selected resources and current folder, check if user can create
// a canvas
function isCreateCanvasEnabled(
  selectedResourceIds: Set<string>,
  currentFolder: IFolder,
  currentUser: IUserInfo
) {
  // Canvases are not allowed in root folder
  if (isRootFolder(currentFolder)) return false;

  // Otherwise same rules apply to canvases as for folders...
  return isCreateFolderEnabled(selectedResourceIds, currentFolder, currentUser);
}

// Given selected resources and current folder, check if user can create
// a folder
function isCreateFolderEnabled(
  selectedResourceIds: Set<string>,
  currentFolder: IFolder,
  currentUser: IUserInfo
) {
  // If anything is selected, create is disabled
  if (selectedResourceIds.size !== 0) return false;

  // Only admin can create folders in the root folder
  if (isRootFolder(currentFolder) && !currentUser.admin) return false;

  return currentFolder.access === "owner" || currentFolder.access === "edit";
}

// Given selected resources, check if user can copy them
function isCopyEnabled(selectedResourceIds: Set<string>) {
  // If nothing selected, copy is disabled
  if (selectedResourceIds.size === 0) return false;

  // TODO: check access as well?

  return true;
}

// Given selected resources, check if user can move them
function isMoveEnabled(selectedResourceIds: Set<string>) {
  // TODO: fix me, not the same
  return isCopyEnabled(selectedResourceIds);
}

// Check if the given folder has any child resources
function isFolderEmpty(
  folderId: string,
  canvases: CanvasByIdMap,
  folders: FolderByIdMap
) {
  for (const canvas of canvases.values()) {
    if (canvas.folder_id === folderId) return false;
  }

  for (const folder of folders.values()) {
    if (folder.folder_id === folderId) return false;
  }

  return true;
}

import { Dropdown, Menu, Typography } from "antd";
import React from "react";
import { MenuOutlined } from "@ant-design/icons";
import { useAuthDataContext } from "../../components/AuthProvider";
import { UserAvatarMenu } from "../../components/UserAvatarMenu";
import { Link } from "react-router-dom";

import "./CanvasPageHeader.less";

interface ICanvasPageHeaderProps {
  name?: string;
  openInDesktopAppURL?: URL;
}

// Header for a canvas page
// TODO: could make it possible to rename canvas from here
export const CanvasPageHeader: React.FunctionComponent<ICanvasPageHeaderProps> =
  (props) => {
    const { isAuthenticated } = useAuthDataContext();

    const menu = (
      <Menu>
        <Menu.ItemGroup>
          <Menu.Item key="back-to-list" disabled={!isAuthenticated}>
            <Link to="/canvases">Back to canvas list</Link>
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item
            key="open-in-desktop"
            disabled={props.openInDesktopAppURL === undefined}
          >
            <a href={props.openInDesktopAppURL?.href || "#"}>
              Open in desktop app
            </a>
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    );

    return (
      <div className="header-container">
        <Dropdown
          overlayClassName="canvas-page-main-menu"
          overlay={menu}
          trigger={["click"]}
          placement="bottomLeft"
          arrow
        >
          <MenuOutlined className="ant-dropdown-link" />
        </Dropdown>

        {props.name && (
          <Typography.Text className="canvas-name">
            {props.name}
          </Typography.Text>
        )}

        {isAuthenticated && <UserAvatarMenu />}
      </div>
    );
  };

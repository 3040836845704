import { useEffect, useRef, useState } from "react";
import { useAuthDataContext } from "../../components/AuthProvider";
import {
  FolderByIdMap,
  isHomeFolderId,
  isRootFolder,
  isTrashFolderId,
  useFoldersContext,
} from "../../components/FoldersProvider";
import {
  DeleteOutlined,
  HomeOutlined,
  FileOutlined,
  FolderOutlined,
  FolderOpenOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { AntTreeNodeProps, DataNode } from "antd/lib/tree";
import { DraggedItem, isDropAllowed } from "../../dragAndDrop";
import { DragAndDropTypes } from "../../constants";
import { useDrag, useDrop } from "react-dnd";
import { moveResourceWithConflictResolutionModal } from "../../api";
import { useCanvasesContext } from "../../components/CanvasesProvider";
import { Tree } from "antd";

export interface IFolderTreeProps {
  rootNodeName: string;
  selectedFolderId: string;
  onSelectFolder: (selectedKeys: React.Key[]) => void;
}

// Component to visualize canvas folders in a tree hierarchy.
export const FolderTree: React.FunctionComponent<IFolderTreeProps> = (
  props
) => {
  const { folders } = useFoldersContext();
  const { user } = useAuthDataContext();

  // Folder hierarchy in a data format suitable for antd
  const [treeData, setTreeData] = useState<IFolderHierarchyNode[]>([]);

  // Update folder hierarchy as needed
  useEffect(() => {
    const newRoot = constructFolderHierarchy(props.rootNodeName, folders);

    setTreeData([newRoot]);
  }, [props.rootNodeName, folders]);

  // The user's home folder's id equals their user id
  const userHomeFolderKey: string = user.id.toString();

  // We can't set the default value here since we don't know the root folder id
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  // Used to track when we can initialized expandedKeys
  const [isExpandedKeysInitialized, setIsExpandedKeysInitialized] =
    useState<boolean>(true);

  // Hook to set the initial value of expandedKeys
  useEffect(() => {
    // Since folders is initialized asynchroniously, we need to check it has
    // been initialized as well before using it.
    if (isExpandedKeysInitialized && folders.size > 0) {
      const rootFolderKey = findRootFolderKey(folders);

      setExpandedKeys([userHomeFolderKey, rootFolderKey]);
      setIsExpandedKeysInitialized(false);
    }
  }, [folders, isExpandedKeysInitialized, userHomeFolderKey]);

  // Determine an icon for given node in the tree hierarchy
  function getIcon(nodeProps: AntTreeNodeProps) {
    const { isCanvas, expanded } = nodeProps;
    if (isCanvas) {
      return <FileOutlined />;
    }

    const rootKey = treeData[0]?.key;

    if (nodeProps.data.key === rootKey) return <DatabaseOutlined />;
    if (isTrashFolderId(nodeProps.data.key)) return <DeleteOutlined />;
    if (isHomeFolderId(nodeProps.data.key)) return <HomeOutlined />;

    return expanded ? <FolderOpenOutlined /> : <FolderOutlined />;
  }

  function onExpand(eventExpandedKeys: React.Key[]) {
    let newExpandedKeys: string[] = [];

    eventExpandedKeys.forEach((key) => newExpandedKeys.push(key as string));
    setExpandedKeys(newExpandedKeys);
  }

  const treeComponent = (
    <Tree.DirectoryTree
      icon={getIcon}
      expandAction={false}
      titleRender={(node) => {
        return <FolderTreeNode node={node} />;
      }}
      showIcon
      treeData={treeData}
      onExpand={onExpand}
      // Must provide a new array everytime for this to work on initial load
      expandedKeys={[...expandedKeys]}
      selectedKeys={[props.selectedFolderId]}
      onSelect={props.onSelectFolder}
    />
  );

  return treeComponent;
};

interface IFolderHierarchyNode {
  key: string;
  title: string;
  children: IFolderHierarchyNode[];
}

// Maps folder id to IFolderHierarchyNode
type FolderCache = Map<string, IFolderHierarchyNode>;

interface IFolderTreeNodeProps {
  node: DataNode;
}

const FolderTreeNode: React.FunctionComponent<IFolderTreeNodeProps> = (
  props
) => {
  const { folders } = useFoldersContext();
  const { canvases } = useCanvasesContext();
  const { user } = useAuthDataContext();

  const ref = useRef(null);

  const folderId = props.node.key as string;
  const folder = folders.get(folderId);

  const [, drag] = useDrag(
    () => ({
      type: DragAndDropTypes.Folder,
      item: { id: folderId },
      canDrag: () => {
        if (isHomeFolderId(folderId)) return false;

        if (folder && isRootFolder(folder)) return false;

        return true;
      },
    }),
    [folderId, folder]
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [DragAndDropTypes.Folder, DragAndDropTypes.Canvas],
      collect: (monitor) => ({ isOver: monitor.isOver() && monitor.canDrop() }),
      canDrop: (item: DraggedItem, _monitor) => {
        return (
          folder !== undefined &&
          isDropAllowed(folder, item.id, user, canvases, folders)
        );
      },
      drop: (item: DraggedItem, _monitor) => {
        moveResourceWithConflictResolutionModal(
          folderId,
          item.id,
          canvases,
          folders
        );
      },
    }),
    [folder, folderId, user, canvases, folders]
  );

  drag(drop(ref));

  return (
    <span className={isOver ? "drop-target" : ""} ref={ref}>
      {props.node.title}
    </span>
  );
};

// Sort children in a hierarchy of folder nodes
function sortChildren(folderCache: FolderCache) {
  // Sorting arbitrary strings in case-insensitive manner is a language-specific
  // feature. We use the preferred content language from the web browser.
  let languages = navigator.languages
    ? navigator.languages
    : [navigator.language];
  if (languages.length === 0) {
    languages = ["en"];
  }
  // navigator.languages is readonly string[], so we need to use "as string[]",
  // since Intl.Collator takes in just string[].
  const collator = new Intl.Collator(languages as string[], {
    sensitivity: "accent",
  });
  for (let [, node] of folderCache) {
    node.children.sort((a, b) => {
      return collator.compare(a.title, b.title);
    });
  }
}

function constructFolderHierarchy(
  rootNodeName: string,
  folders: FolderByIdMap
): IFolderHierarchyNode {
  let root: IFolderHierarchyNode = {
    key: "",
    title: "",
    children: [],
  };
  const folderCache: FolderCache = new Map();

  // Update root node and populate folder cache
  folders.forEach((folder) => {
    const folderNode: IFolderHierarchyNode = {
      key: folder.id,
      title: folder.name,
      children: [],
    };

    if (isRootFolder(folder)) {
      root = folderNode;
      root.title = rootNodeName.length > 0 ? rootNodeName : "Canvus Connect";
    }

    folderCache.set(folder.id, folderNode);
  });

  // Construct the folder hierarchy
  folders.forEach((folder) => {
    if (!isRootFolder(folder)) {
      const node = folderCache.get(folder.id);
      const parent = folderCache.get(folder.folder_id);

      if (parent && node) {
        parent.children.push(node);
      } else {
        console.error(
          `Folder ${folder.id} or its parent ${folder.folder_id} were not found in cache.`,
          folder
        );
      }
    }
  });

  // Sort the children in the hierarchy by name
  sortChildren(folderCache);

  return root;
}

// Find the root folder id from a map of IFolders
function findRootFolderKey(folders: FolderByIdMap): string {
  for (let [, value] of folders) {
    if (isRootFolder(value)) return value.id;
  }

  return "";
}

import React, { useEffect, useState } from "react";
import { Button, Layout, Result } from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import { PageLoading } from "../../components/PageLoading";
import { ICanvas, IServerConfig } from "../../interfaces";
import { CanvasPageHeader } from "./CanvasPageHeader";
import { subscribe, SubscribeCallbackFn, unsubscribe } from "../../backend";
import { CanvasToolbar } from "./CanvasToolbar";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { QueryFailure } from "../../components/QueryFailure";
import { toCanvusUrl } from "../../util";

import "./CanvasPage.less";

interface ICanvasPageLayoutProps {
  name?: string;
  openInDesktopAppURL?: URL;
}

// Layout for canvas page with custom header
const CanvasPageLayout: React.FunctionComponent<ICanvasPageLayoutProps> = (
  props
) => {
  return (
    <Layout className="site-layout-background">
      <Layout.Header className="canvas-page">
        <CanvasPageHeader
          name={props.name}
          openInDesktopAppURL={props.openInDesktopAppURL}
        />
      </Layout.Header>
      <Layout.Content>{props.children}</Layout.Content>
    </Layout>
  );
};

export const CanvasPage: React.FunctionComponent = () => {
  const params = useParams();
  const id = params.canvasId;

  const [activeCanvas, setActiveCanvas] = useState<ICanvas>();
  const [isCanvasError, setIsCanvasError] = useState<boolean>(false);
  const location = useLocation();

  // Subscribe to the canvas to monitor changes to it
  useEffect(() => {
    const endpoint = `/canvases/${id}`;

    function handleCanvasChange(canvas: ICanvas) {
      setActiveCanvas(canvas);
    }

    // Handle errors from subscribe request
    const callbackFn: SubscribeCallbackFn = (status) => {
      if (status.ok === false) {
        setIsCanvasError(true);
        setActiveCanvas(undefined);
      }
    };

    subscribe(endpoint, handleCanvasChange, callbackFn);

    return function cleanup() {
      unsubscribe(endpoint, handleCanvasChange);
    };
  }, [id]);

  // Query server config to obtain external URL
  const {
    isLoading: isConfigLoading,
    error: configError,
    data: config,
  } = useQuery<IServerConfig, AxiosError>("/server-config");

  if (configError) {
    return (
      <CanvasPageLayout>
        <QueryFailure
          title="Failed to query server configuration"
          error={configError}
        />
      </CanvasPageLayout>
    );
  }

  if (isCanvasError || id === undefined) {
    return (
      <CanvasPageLayout>
        <Result
          status="error"
          title="Canvas not found"
          subTitle="Either this canvas does not exist or you don't have permission to view it. Ask the canvas owner to verify the link and/or update permissions."
          extra={
            <Link to="/users/login" state={{ from: location }}>
              <Button type="primary">Sign in</Button>
            </Link>
          }
        />
      </CanvasPageLayout>
    );
  }

  if (isConfigLoading || activeCanvas === undefined) {
    return (
      <CanvasPageLayout>
        <PageLoading />
      </CanvasPageLayout>
    );
  }

  const openInDesktopAppURL = toCanvusUrl(new URL(config!.external_url), id);

  return (
    <CanvasPageLayout
      openInDesktopAppURL={openInDesktopAppURL}
      name={activeCanvas.name}
    >
      <div className="canvas-container">
        <CanvasToolbar
          external_url={config!.external_url}
          canvas={activeCanvas}
        />
        <div className="canvas-placeholder"></div>
      </div>
    </CanvasPageLayout>
  );
};

import React, { useState } from "react";
import { Button, Card, Form, Input, message, Result, Row } from "antd";
import {
  MailOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { Link, RouteProps, useSearchParams } from "react-router-dom";
import Axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { QueryFailure } from "../components/QueryFailure";
import PageLoading from "../components/PageLoading";
import { narrowFormLayout, narrowFormTailLayout } from "./FormLayout";

// Reuse styles from login page for consistent look
import "./LoginPage.less";
import PageLayout from "./PageLayout";

interface ICreateResetTokenProps {
  onSubmit: (values: any) => void;
}

// Component to create a password reset token
export const CreateResetToken: React.FunctionComponent<ICreateResetTokenProps> =
  (props) => {
    return (
      <Card title="Reset password" className="login-card">
        <Form
          name="create-password-reset-token"
          className="login-form"
          onFinish={props.onSubmit}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please provide a valid email.",
              },
            ]}
          >
            <Input prefix={<MailOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Reset password
            </Button>
          </Form.Item>
          <Form.Item>
            Already have login and password?{" "}
            <Link to="/users/login">Sign in</Link>.
          </Form.Item>
        </Form>
      </Card>
    );
  };

interface IResetPasswordProps {
  token: string;
  onSubmit: (values: any) => void;
}

export const ResetPassword: React.FunctionComponent<IResetPasswordProps> = (
  props
) => {
  // Validate reset token
  const { isLoading, error } = useQuery<unknown, AxiosError>(
    `/users/password/validate-reset-token?token=${props.token}`
  );

  if (error) {
    return <QueryFailure title="Invalid reset token" error={error} />;
  }

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <Card title="Reset password" className="login-card">
      <Form
        name="reset-password"
        className="login-form"
        onFinish={props.onSubmit}
        {...narrowFormLayout}
      >
        <Form.Item
          label="New password"
          name="password"
          rules={[
            {
              required: true,
              type: "string",
              message: "New password is required",
            },
          ]}
        >
          <Input.Password
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Form.Item
          label="Confirm password"
          name="confirm_password"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Please confirm your new password",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  "The two passwords provided do not match"
                );
              },
            }),
          ]}
        >
          <Input.Password
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Form.Item {...narrowFormTailLayout}>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Reset password
          </Button>
        </Form.Item>
        <Form.Item>
          Already have login and password?{" "}
          <Link to="/users/login">Sign in</Link>.
        </Form.Item>
      </Form>
    </Card>
  );
};

export const ResultTokenCreated: React.FunctionComponent = () => {
  return (
    <Card className="login-card">
      <Result
        title="Password reset"
        subTitle="If your email address exists in our database, you will receive a password reset link in your email in a few minutes."
        extra={
          <Link to="/users/login">
            <Button type="primary">Sign in</Button>
          </Link>
        }
      />
    </Card>
  );
};

export const ResultPasswordReset: React.FunctionComponent = () => {
  return (
    <Card className="login-card">
      <Result
        status="success"
        title="Password reset"
        subTitle="Your password was reset."
        extra={
          <Link to="/users/login">
            <Button type="primary">Sign in</Button>
          </Link>
        }
      />
    </Card>
  );
};

enum State {
  CreateToken,
  ResultTokenCreated,
  ResetPassword,
  ResultPasswordReset,
}

export const ResetPasswordPage: React.FunctionComponent<RouteProps> = (
  props
) => {
  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get("token");

  // Default state depends on whether a token was supplied as query param
  const defaultState: State =
    resetToken === null ? State.CreateToken : State.ResetPassword;

  const [componentState, setComponentState] = useState<State>(defaultState);

  function onCreatePasswordResetToken(values: any) {
    const payload = { email: values.email };

    Axios.post("/api/dashboard/users/password/create-reset-token", payload)
      .then((_response) => {
        setComponentState(State.ResultTokenCreated);
      })
      .catch((_error) => {
        message.error("Failed to reset password.");
      });
  }

  function onPasswordReset(values: any) {
    const payload = {
      token: resetToken,
      password: values.password,
    };

    Axios.post("/api/dashboard/users/password/reset", payload)
      .then((_response) => {
        setComponentState(State.ResultPasswordReset);
      })
      .catch((_error) => {
        message.error("Failed to reset password.");
      });
  }

  let component;
  switch (componentState) {
    case State.CreateToken:
      component = <CreateResetToken onSubmit={onCreatePasswordResetToken} />;
      break;
    case State.ResultTokenCreated:
      component = <ResultTokenCreated />;
      break;
    case State.ResetPassword:
      component = (
        <ResetPassword token={resetToken!} onSubmit={onPasswordReset} />
      );
      break;
    case State.ResultPasswordReset:
      component = <ResultPasswordReset />;
      break;
  }

  return (
    <PageLayout>
      <Row justify="center" align="middle">
        {component}
      </Row>
    </PageLayout>
  );
};

import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Collapse,
  Divider,
  Form,
  Input,
  message,
  notification,
  Typography,
} from "antd";
import AdminLayout from "./AdminLayout";
import Axios, { AxiosError } from "axios";
import { IServerConfig } from "../../interfaces";
import { formLayout, formTailLayout } from "../FormLayout";
import PageLoading from "../../components/PageLoading";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryFailure } from "../../components/QueryFailure";

interface IWrapperProps {
  onAlertClose: () => void;
  errorMessage?: string;
}

const PageWrapper: React.FunctionComponent<IWrapperProps> = (props) => {
  return (
    <AdminLayout selectedSideMenuKey="settings.authentication">
      {props.errorMessage !== undefined && (
        <Alert
          message="Failed to save settings"
          description={props.errorMessage}
          type="error"
          showIcon
          closable
          onClose={props.onAlertClose}
        />
      )}
      <Card title="Authentication settings">{props.children}</Card>
    </AdminLayout>
  );
};

export const AdminSettingsAuthenticationPage: React.FunctionComponent = () => {
  const { isLoading, data, error } =
    useQuery<IServerConfig, AxiosError>("/server-config");

  const mutation = useMutation<IServerConfig, AxiosError, any>(
    async (config) => {
      const { data } = await Axios.patch(
        "/api/dashboard/server-config",
        config
      );
      return data;
    }
  );

  const [errorMessage, setErrorMessage] = useState<string>();

  const queryClient = useQueryClient();

  function clearError() {
    setErrorMessage(undefined);
  }

  function onSubmit(values: any) {
    const restartNotificationKey = "server-restart-required";

    mutation.mutate(values, {
      onSuccess: (data) => {
        message.success("Settings saved.");
        clearError();
        notification.close(restartNotificationKey);
        queryClient.setQueryData("/server-config", data);

        const isRestartRequired = values.authentication?.saml !== undefined;

        if (isRestartRequired) {
          notification.info({
            key: restartNotificationKey,
            message: "Server restart required",
            description: (
              <>
                The{" "}
                <Typography.Text style={{ whiteSpace: "nowrap" }} code>
                  mt-canvus-dashboard
                </Typography.Text>{" "}
                service must be restarted for the changes to have effect.
              </>
            ),
            duration: 0,
          });
        }
      },
      onError: (error) => {
        const msgFromServer = error.response?.data?.msg;
        setErrorMessage(msgFromServer);
      },
    });
  }

  if (error) {
    return (
      <PageWrapper onAlertClose={clearError}>
        <QueryFailure
          title="Failed to query server configuration"
          error={error}
        />
      </PageWrapper>
    );
  }

  if (isLoading) {
    return (
      <PageWrapper onAlertClose={clearError}>
        <PageLoading />;
      </PageWrapper>
    );
  }

  return (
    <PageWrapper errorMessage={errorMessage} onAlertClose={clearError}>
      {data!.access === "ro" && (
        <Alert
          type="warning"
          message="The server configuration is read-only"
          description="The server configuration can not be changed because the server does not have write access to it. Check the permissions on the server configuration file."
          closable={true}
          banner={true}
        />
      )}
      <Collapse>
        <Collapse.Panel header="General sign-up restrictions" key="general">
          <GeneralSettings serverConfig={data!} onSubmit={onSubmit} />
        </Collapse.Panel>
        <Collapse.Panel header="Password authentication" key="password">
          <PasswordSettings serverConfig={data!} onSubmit={onSubmit} />
        </Collapse.Panel>
        <Collapse.Panel header="SAML authentication" key="saml">
          <SamlSettings serverConfig={data!} onSubmit={onSubmit} />
        </Collapse.Panel>
        <Collapse.Panel header="QR code authentication" key="qrcode">
          <QRSettings serverConfig={data!} onSubmit={onSubmit} />
        </Collapse.Panel>
      </Collapse>
    </PageWrapper>
  );
};

interface IProps {
  serverConfig: IServerConfig;
  onSubmit: (values: any) => void;
}

const GeneralSettings: React.FunctionComponent<IProps> = (props) => {
  const isReadOnly = props.serverConfig.access === "ro";
  const config = props.serverConfig.authentication;

  function submitForm(values: any): void {
    // Split allowed domains user input by whitespace or comma
    const domainList = values.domain_allow_list.split(/[\s,]+/);

    const payload = {
      authentication: {
        require_admin_approval: values.require_admin_approval,
        domain_allow_list: domainList,
      },
    };

    props.onSubmit(payload);
  }

  return (
    <>
      Set general restrictions to way users sign-up.
      <Form
        {...formLayout}
        name="general"
        initialValues={{
          require_admin_approval: config.require_admin_approval,
          domain_allow_list: config.domain_allow_list.toString(),
        }}
        onFinish={submitForm}
      >
        <Form.Item
          label="Require admin approval"
          name="require_admin_approval"
          help="When enabled, any user signing up will have to be explicitly approved by an admin before they can sign in."
          valuePropName="checked"
        >
          <Checkbox disabled={isReadOnly} />
        </Form.Item>

        <Form.Item
          label="Allowed domains"
          name="domain_allow_list"
          help="Only users with e-mail addresses that match these domain(s) will be able to sign-up. Wildcards allowed. Separate multiple entries with whitespace or commas. For example: domain.com, *.domain.com"
          rules={[
            {
              required: true,
              message: "Please specify allowed domains",
            },
          ]}
        >
          <Input.TextArea disabled={isReadOnly} rows={8} />
        </Form.Item>

        <Form.Item {...formTailLayout}>
          <Button disabled={isReadOnly} type="primary" htmlType="submit">
            Save changes
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const PasswordSettings: React.FunctionComponent<IProps> = (props) => {
  const isReadOnly = props.serverConfig.access === "ro";
  const config = props.serverConfig.authentication.password;

  function submitForm(values: any): void {
    const payload = {
      authentication: { password: { ...values } },
    };
    props.onSubmit(payload);
  }

  // Used to disable parts of the UI depending on user selection
  const [isPasswordEnabled, setIsPasswordEnabled] = useState<boolean>(
    config.enabled
  );

  return (
    <>
      Set restrictions to password authentication.
      <Form
        {...formLayout}
        name="password"
        initialValues={{
          enabled: config.enabled,
          sign_up_enabled: config.sign_up_enabled,
        }}
        onFinish={submitForm}
      >
        <Form.Item
          label="Enabled"
          name="enabled"
          help={`If enabled, existing users can sign-in at ${props.serverConfig.external_url}/users/login using their password.`}
          valuePropName="checked"
        >
          <Checkbox
            disabled={isReadOnly}
            onChange={(e) => setIsPasswordEnabled(e.target.checked)}
          />
        </Form.Item>

        <Form.Item
          label="Allow sign-up"
          name="sign_up_enabled"
          help={`If enabled, any user will be able to register a new account at ${props.serverConfig.external_url}/users/register using a password.`}
          valuePropName="checked"
        >
          <Checkbox disabled={isReadOnly || !isPasswordEnabled} />
        </Form.Item>

        <Form.Item {...formTailLayout}>
          <Button disabled={isReadOnly} type="primary" htmlType="submit">
            Save changes
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const QRSettings: React.FunctionComponent<IProps> = (props) => {
  const isReadOnly = props.serverConfig.access === "ro";
  const config = props.serverConfig.authentication.qr_code;

  function submitForm(values: any): void {
    const payload = {
      authentication: { qr_code: { ...values } },
    };
    props.onSubmit(payload);
  }

  return (
    <>
      Set restrictions to QR code authentication.
      <Form
        {...formLayout}
        name="qrcode"
        initialValues={{
          enabled: config.enabled,
        }}
        onFinish={submitForm}
      >
        <Form.Item
          label="Enabled"
          name="enabled"
          help={`If enabled, existing users can sign-in at ${props.serverConfig.external_url}/users/login using a QR code.`}
          valuePropName="checked"
        >
          <Checkbox disabled={isReadOnly} />
        </Form.Item>

        <Form.Item {...formTailLayout}>
          <Button disabled={isReadOnly} type="primary" htmlType="submit">
            Save changes
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

// TODO: add option to upload metadata XML
const SamlSettings: React.FunctionComponent<IProps> = (props) => {
  const isReadOnly = props.serverConfig.access === "ro";
  const config = props.serverConfig.authentication.saml;

  function submitForm(values: any): void {
    const payload = { authentication: { saml: { ...values } } };
    props.onSubmit(payload);
  }

  // Used to disable parts of the UI depending on user selection
  const [isSamlEnabled, setIsSamlEnabled] = useState<boolean>(config.enabled);

  return (
    <>
      Configure and set restrictions to SAML authentication.
      <Form
        {...formLayout}
        name="saml"
        initialValues={{
          enabled: config.enabled,
          sign_up_enabled: config.sign_up_enabled,
          sp_entity_id: config.sp_entity_id,
          idp_target_url: config.idp_target_url,
          idp_cert_finger_print: config.idp_cert_finger_print,
          idp_entity_id: config.idp_entity_id,
        }}
        onFinish={submitForm}
      >
        <Form.Item
          label="Enabled"
          name="enabled"
          help={`If enabled, users can sign-in at ${props.serverConfig.external_url}/users/login using SAML.`}
          valuePropName="checked"
        >
          <Checkbox
            disabled={isReadOnly}
            onChange={(e) => setIsSamlEnabled(e.target.checked)}
          />
        </Form.Item>

        <Form.Item
          label="Allow sign-up"
          name="sign_up_enabled"
          help={`If enabled, any user will be able to register a new account at ${props.serverConfig.external_url}/users/register using SAML.`}
          valuePropName="checked"
        >
          <Checkbox disabled={isReadOnly || !isSamlEnabled} />
        </Form.Item>

        <Divider orientation="left">Service Provider</Divider>

        <Form.Item label="ACS URL">
          <Typography.Text type="secondary" copyable>
            {config.acs_url}
          </Typography.Text>
        </Form.Item>

        <Form.Item
          label="Entity ID"
          name="sp_entity_id"
          rules={[
            { required: isSamlEnabled, message: "Entity ID is required." },
          ]}
        >
          <Input disabled={isReadOnly || !isSamlEnabled} />
        </Form.Item>

        <Divider orientation="left">Identity Provider</Divider>

        <Form.Item
          label="Target URL"
          name="idp_target_url"
          rules={[
            { required: isSamlEnabled, message: "Target URL is required." },
          ]}
        >
          <Input disabled={isReadOnly || !isSamlEnabled} />
        </Form.Item>
        <Form.Item
          label="Certificate fingerprint"
          name="idp_cert_finger_print"
          rules={[
            {
              required: isSamlEnabled,
              message: "Certificate fingerprint is required.",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const reSHA256WithoutColons = /[A-Fa-f0-9]{64}/;
                const reSHA256WithColons =
                  /[A-Fa-f0-9]{2}(:[A-Fa-f0-9]{2}){31}/;

                if (reSHA256WithColons.test(value)) return Promise.resolve();
                if (reSHA256WithoutColons.test(value)) return Promise.resolve();

                return Promise.reject("Invalid SHA256 checksum.");
              },
            }),
          ]}
          tooltip="SHA256 checksum of the X.509 certificate. For example: a8b9dbf2a05f114f97a0c80330dbe47e714b853482ffeff06cc9ef87d161ec22 or a8:b9:db:f2:a0:5f:11:4f:97:a0:c8:03:30:db:e4:7e:71:4b:85:34:82:ff:ef:f0:6c:c9:ef:87:d1:61:ec:22. If you don't know the fingerprint, you can enter any SHA256 checksum, try to sign-in and review the server log to find the correct value."
        >
          <Input disabled={isReadOnly || !isSamlEnabled} />
        </Form.Item>
        <Form.Item
          label="Entity ID"
          name="idp_entity_id"
          rules={[
            { required: isSamlEnabled, message: "Entity ID is required." },
          ]}
        >
          <Input disabled={isReadOnly || !isSamlEnabled} />
        </Form.Item>

        <Form.Item {...formTailLayout}>
          <Button disabled={isReadOnly} type="primary" htmlType="submit">
            Save changes
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

import React, { useState, useEffect } from "react";
import {
  Card,
  Radio,
  Input,
  Table,
  Button,
  Dropdown,
  Menu,
  Space,
  Typography,
  Modal,
  message,
} from "antd";
import AdminLayout from "./AdminLayout";
import UsersProvider, { useUsersContext } from "../../components/UsersProvider";
import {
  SettingOutlined,
  DownOutlined,
  ExclamationOutlined,
} from "@ant-design/icons";

import "./AdminUsersPage.less";
import { IUserInfo, useAuthDataContext } from "../../components/AuthProvider";
import { RadioChangeEvent } from "antd/lib/radio";
import Axios from "axios";
import { Link } from "react-router-dom";
import { guestUserId } from "../../constants";

function approveUser(user: IUserInfo) {
  const url = `/api/dashboard/users/${user.id}/approve`;
  Axios.post(url)
    .then((_response) => {
      message.success(`User ${user.name} was approved.`);
    })
    .catch((_error) => {
      message.error(`Failed to approve user ${user.name}`);
    });
}

function toggleUserBlock(user: IUserInfo) {
  const baseUrl = "/api/dashboard/users/" + user.id;
  const url = baseUrl + (user.blocked === true ? "/unblock" : "/block");
  let successMessage: string, failureMessage: string;

  if (user.blocked) {
    successMessage = `User ${user.name} was unblocked.`;
    failureMessage = `Failed to unblock user ${user.name}.`;
  } else {
    successMessage = `User ${user.name} was blocked.`;
    failureMessage = `Failed to block user ${user.name}.`;
  }

  Axios.post(url)
    .then((_response) => {
      message.success(successMessage);
    })
    .catch((error) => {
      console.error(failureMessage, error.response);
      message.error(failureMessage);
    });
}

function toggleBlock(user: IUserInfo) {
  // Unblocking user requires no confirmation
  if (user.blocked) {
    toggleUserBlock(user);
    return;
  }

  Modal.confirm({
    title: `Block user ${user.name}?`,
    icon: <ExclamationOutlined />,
    content: (
      <div>
        <p>Blocking the user has the following effects:</p>
        <ul>
          <li>User will not be able to sign in</li>
          <li>User will not be able to access canvases</li>
          <li>Canvases and folders owned by the user will be kept</li>
        </ul>
      </div>
    ),
    onOk() {
      toggleUserBlock(user);
    },
  });
}

function deleteUser(user: IUserInfo) {
  Modal.confirm({
    title: `Delete user ${user.name}?`,
    icon: <ExclamationOutlined />,
    content: (
      <div>
        <p>Deleting the user has the following effects:</p>
        <ul>
          <li>User will not be able to sign in</li>
          <li>User will not be able to access canvases</li>
          <li>
            All resources owned by the user will be deleted except for folders
            that contain resources owned by other users
          </li>
        </ul>
      </div>
    ),
    onOk() {
      Axios.delete("/api/dashboard/users/" + user.id)
        .then((_response) => {
          message.success(`User ${user.name} was deleted.`);
        })
        .catch((error) => {
          console.error("Failed to delete user:", error.response);
          const msgFromServer = error.response?.data?.msg;
          message.error(
            `Failed to delete user ${user.name}. (${msgFromServer})`
          );
        });
    },
  });
}

function isValidDate(date: Date) {
  return date instanceof Date && !isNaN(date.getTime());
}

function formatTable(currentUser: IUserInfo) {
  return [
    {
      title: "User",
      dataIndex: "name",
      render: (_text: unknown, record: IUserInfo) => {
        return (
          <span>
            <Typography.Text>{record.name}</Typography.Text>
            <br />
            <Typography.Text type="secondary">{record.email}</Typography.Text>
          </span>
        );
      },
    },
    {
      title: "Created at",
      render: (_text: unknown, record: IUserInfo) => {
        // Convert UTC to local time
        const date = new Date(record.created_at);
        return isValidDate(date) ? date.toString() : "N/A";
      },
    },
    {
      title: "Last activity",
      render: (_text: unknown, record: IUserInfo) => {
        // Convert UTC to local time
        const date = new Date(record.last_login);
        return isValidDate(date) ? date.toString() : "N/A";
      },
    },
    {
      title: "",
      // https://stackoverflow.com/questions/61519622/antd-with-typescript-table-with-column-align-right-is-not-compiling
      align: "right" as "right",
      render: (_text: unknown, record: IUserInfo) => {
        const isCurrentUser = record.id === currentUser.id;
        const isGuestOrCurrentUser = record.id === guestUserId || isCurrentUser;

        const menu = (
          <Menu>
            {record.approved === false ? (
              <Menu.Item onClick={() => approveUser(record)} key="0">
                Approve
              </Menu.Item>
            ) : (
              <Menu.Item
                disabled={isCurrentUser}
                onClick={() => toggleBlock(record)}
                key="1"
              >
                {record.blocked ? "Unblock" : "Block"}
              </Menu.Item>
            )}
            <Menu.Divider />
            <Menu.Item
              disabled={isGuestOrCurrentUser}
              onClick={() => deleteUser(record)}
              key="2"
            >
              <Typography.Text disabled={isGuestOrCurrentUser} type="danger">
                Delete
              </Typography.Text>
            </Menu.Item>
          </Menu>
        );

        return (
          <Space size="small">
            <Link to={"/admin/users/" + record.id + "/edit"}>
              <Button>Edit</Button>
            </Link>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                <SettingOutlined />
                <DownOutlined />
              </Button>
            </Dropdown>
          </Space>
        );
      },
    },
  ];
}

enum FilterType {
  Active,
  Admins,
  Blocked,
  PendingApproval,
  All,
}

export const AdminUsersPage: React.FunctionComponent = () => {
  return (
    <UsersProvider>
      <AdminUsersPageContent />
    </UsersProvider>
  );
};

const AdminUsersPageContent: React.FunctionComponent = () => {
  const { users } = useUsersContext();
  const [filterType, setFilterType] = useState<FilterType>(FilterType.Active);
  const [filterString, setFilterString] = useState<string>("");
  const [filteredUsers, setFilteredUsers] = useState<IUserInfo[]>(
    Array.from(users.values())
  );

  const { user } = useAuthDataContext();

  const columns = formatTable(user);

  // Only search in visible columns to avoid confusing the user. Use string
  // instead of an array to workaround the fact that arrays can't be compared in
  // JavaScript. This avoids infinite loop in the hook below.
  const keysToSearch = columns
    .filter((x) => x.dataIndex)
    .map((x) => x.dataIndex)
    .join();

  // Handle table filtering. Modifying filters will trigger hook below to do
  // the actual filtering.
  function onFilterStringChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFilterString(event.target.value.toLowerCase());
  }

  function onFilterTypeChange(event: RadioChangeEvent) {
    setFilterType(event.target.value);
  }

  // Hook to perform the actual data filtering
  useEffect(() => {
    const userArray = Array.from(users.values());

    const result = userArray.filter((user) => {
      // Filter by type first
      if (filterType === FilterType.Active && user.blocked === true)
        return false;

      if (filterType === FilterType.Admins && user.admin === false)
        return false;

      if (filterType === FilterType.Blocked && user.blocked === false)
        return false;

      if (filterType === FilterType.PendingApproval && user.approved === true)
        return false;

      // Convert string back to array
      const keyArray = keysToSearch.split(",");

      // Filter by search string
      for (const key in user) {
        if (keyArray.includes(key)) {
          const value = String((user as any)[key]).toLowerCase();

          if (value.includes(filterString)) return true;
        }
      }

      return false;
    });

    setFilteredUsers(result);
  }, [users, filterType, filterString, keysToSearch]);

  // Count different user types for statistics
  const userArray = Array.from(users.values());

  const adminUserCount = userArray.filter((user) => user.admin === true).length;
  const blockedUserCount = userArray.filter(
    (user) => user.blocked === true
  ).length;
  const pendingApprovalUserCount = userArray.filter(
    (user) => user.approved === false
  ).length;
  const activeUserCount =
    userArray.length - blockedUserCount - pendingApprovalUserCount;

  return (
    <AdminLayout selectedSideMenuKey="users">
      <Card>
        <Radio.Group
          defaultValue={FilterType.Active}
          onChange={onFilterTypeChange}
          className="filter-buttons"
        >
          <Radio.Button value={FilterType.Active}>
            Active ({activeUserCount})
          </Radio.Button>
          <Radio.Button value={FilterType.Admins}>
            Admins ({adminUserCount})
          </Radio.Button>
          <Radio.Button value={FilterType.Blocked}>
            Blocked ({blockedUserCount})
          </Radio.Button>
          <Radio.Button value={FilterType.PendingApproval}>
            Pending approval ({pendingApprovalUserCount})
          </Radio.Button>
          <Radio.Button value={FilterType.All}>
            All ({userArray.length})
          </Radio.Button>
        </Radio.Group>
        <Link to="/admin/users/new">
          <Button type="primary" className="inline-button">
            New user
          </Button>
        </Link>

        <Input.Search
          className="filter-input"
          placeholder="Filter results..."
          onChange={onFilterStringChange}
        ></Input.Search>
        <Table<IUserInfo>
          columns={columns}
          dataSource={filteredUsers}
          rowKey="id"
        />
      </Card>
    </AdminLayout>
  );
};

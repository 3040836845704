import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  message,
  Popover,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import {
  UploadOutlined,
  FileTextOutlined,
  InboxOutlined,
} from "@ant-design/icons";

import { ICanvas } from "../../interfaces";

import { UploadRequestOption } from "rc-upload/lib/interface";

import "./CanvasToolbar.less";
import Axios from "axios";
import { RcFile } from "antd/lib/upload";

export interface ICanvasToolbarProps {
  external_url: string;
  canvas: ICanvas;
}

enum ActiveTool {
  None,
  Upload,
  Note,
}

enum NoteColor {
  Purple = "#662583",
  Green = "#3aaa34",
  White = "#ffffff",
  Red = "#e6332a",
  Yellow = "#fcea0f",
  Blue = "#1d71b8",
}

export const CanvasToolbar: React.FunctionComponent<ICanvasToolbarProps> = (
  props
) => {
  const [activeTool, setActiveTool] = useState<ActiveTool>(ActiveTool.None);

  function handleVisibleChange(visibleTool: ActiveTool) {
    setActiveTool(visibleTool);
  }

  function onUploadNote(title: string, text: string, color: NoteColor) {
    const url = `/api/dashboard/canvases/${props.canvas.id}/uploads-folder`;

    // Need to use multipart upload
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const json = {
      upload_type: "note",
      title: title,
      text: text,
      background_color: color,
    };
    const formData = new FormData();
    formData.append("json", JSON.stringify(json));

    Axios.post(url, formData, config)
      .then((_response) => {
        message.success("Note was uploaded.");
      })
      .catch((_error) => {
        message.error("Failed to upload note");
      });

    setActiveTool(ActiveTool.None);
  }

  return (
    <div className="canvas-toolbar">
      <Popover
        placement="rightTop"
        content={<UploadTool {...props} />}
        trigger="click"
        visible={activeTool === ActiveTool.Upload}
        onVisibleChange={(visible) => {
          handleVisibleChange(visible ? ActiveTool.Upload : ActiveTool.None);
        }}
      >
        <Tooltip title="Upload a file" placement="right">
          <Button
            disabled={props.canvas.access === "view"}
            type={activeTool === ActiveTool.Upload ? "primary" : "default"}
            size="large"
          >
            <UploadOutlined />
          </Button>
        </Tooltip>
      </Popover>

      <Popover
        placement="rightTop"
        content={
          <NoteTool
            onCancel={() => {
              setActiveTool(ActiveTool.None);
            }}
            onUpload={onUploadNote}
          />
        }
        trigger="click"
        visible={activeTool === ActiveTool.Note}
        onVisibleChange={(visible) => {
          handleVisibleChange(visible ? ActiveTool.Note : ActiveTool.None);
        }}
      >
        <Tooltip title="Upload a note" placement="right">
          <Button
            disabled={props.canvas.access === "view"}
            type={activeTool === ActiveTool.Note ? "primary" : "default"}
            size="large"
          >
            <FileTextOutlined />
          </Button>
        </Tooltip>
      </Popover>
    </div>
  );
};

async function uploadImage(options: UploadRequestOption) {
  const { action, onSuccess, onError, file, onProgress } = options;

  const config = {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: (event: any) => {
      // Forward upload progress event to underlying rc-upload to ensure the UI
      // progress bar is updated
      event.percent = Math.floor(100 * (event.loaded / event.total));
      onProgress!(event);
    },
  };

  const rcFile = file as RcFile;
  const json = {
    upload_type: "asset",
    original_filename: rcFile.name,
    title: rcFile.name,
  };

  const formData = new FormData();
  formData.append("json", JSON.stringify(json));
  formData.append("data", file);

  Axios.post(action, formData, config)
    .then((response) => {
      onSuccess!(response.data, response.request);
    })
    .catch((error) => {
      onError!(error);
    });
}

// Upload files dialog
const UploadTool: React.FunctionComponent<ICanvasToolbarProps> = (props) => {
  const uploadURL = `/api/dashboard/canvases/${props.canvas.id}/uploads-folder`;

  return (
    <div className="upload-files">
      <p className="title">Upload Files</p>
      <Upload.Dragger
        name="file"
        multiple={true}
        customRequest={uploadImage}
        action={uploadURL}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click here or drag files to this area to upload.
        </p>
        <p className="ant-upload-hint">
          The uploaded files are stored in the Uploads folder of the canvas.
        </p>
      </Upload.Dragger>
    </div>
  );
};

interface INoteToolProps {
  onUpload: (title: string, text: string, color: NoteColor) => void;
  onCancel: () => void;
}

// Dialog to upload notes
const NoteTool: React.FunctionComponent<INoteToolProps> = (props) => {
  const [selectedColor, setSelectedColor] = useState<NoteColor>(
    NoteColor.Yellow
  );

  const [form] = Form.useForm();

  function onUpload(values: any) {
    props.onUpload(values.title, values.text, selectedColor);
    form.resetFields();
  }

  return (
    <div className="upload-notes">
      <Typography.Paragraph className="title" strong>
        Upload Note
      </Typography.Paragraph>
      <Typography.Paragraph className="hint" type="secondary">
        The uploaded notes are stored in the Uploads folder.
      </Typography.Paragraph>

      <Form layout="vertical" form={form} onFinish={onUpload}>
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              type: "string",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Text"
          name="text"
          rules={[
            {
              required: true,
              type: "string",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item label="Note color">
          <NoteColorSelector
            selectedColor={selectedColor}
            onChange={(color) => {
              setSelectedColor(color);
            }}
          />
        </Form.Item>

        <div className="footer">
          <Button
            onClick={() => {
              form.resetFields();
              props.onCancel();
            }}
          >
            Cancel
          </Button>
          <Button style={{ margin: "0 8px" }} type="primary" htmlType="submit">
            Upload
          </Button>
        </div>
      </Form>
    </div>
  );
};

interface INoteColorSelectorProps {
  selectedColor: NoteColor;
  onChange: (color: NoteColor) => void;
}

const NoteColorSelector: React.FunctionComponent<INoteColorSelectorProps> = (
  props
) => {
  return (
    <div className="note-color-selector">
      {Object.values(NoteColor).map((value, index) => {
        const isSelected = value === props.selectedColor;

        return (
          <div
            key={index}
            className={isSelected ? "color selected" : "color"}
            style={{ backgroundColor: value }}
            onClick={() => {
              props.onChange(value);
            }}
          ></div>
        );
      })}
    </div>
  );
};
